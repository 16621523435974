import {SearchContainer} from "./styles";
import {BaseDatePicker} from "../date/DatePicker";
import {ButtonSearchSubmit} from "./ButtonSearch";


export const SearchForm = () => {
    return (
        <div className="SearchForm">
            <form id="MainSearchForm">
                <h1 className="text-left">Укажите критерии поиска</h1>
                <SearchContainer>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label align-self-center">Дата счета:</label>
                        <div className="col-sm-2">
                            <BaseDatePicker label="Дата с"/>
                        </div>
                        <div className="col-sm-2">
                            <BaseDatePicker label="Дата по"/>
                        </div>
                        <label className="col-sm-2 col-form-label ms-5">Номер счета</label>
                        <div className="col-sm-2">
                            <input type="text" className="form-control"/>
                        </div>

                    </div>
                    <div className="form-group row mt-3">
                        <label className="col-sm-2 col-form-label align-self-center">Срок оплаты:</label>
                        <div className="col-sm-2">
                            <BaseDatePicker label="Дата с"/>
                        </div>
                        <div className="col-sm-2">
                            <BaseDatePicker label="Дата по"/>
                        </div>
                        <label className="col-sm-2 col-form-label ms-5">Номер заказа:</label>
                        <div className="col-sm-2">
                            <input type="text" className="form-control"/>
                        </div>

                    </div>
                    <div className="form-group row mt-3">
                        <label className="col-sm-2 col-form-label">Тип счета:</label>
                        <div className="col-sm-4">
                            <select className="form-select">
                                <option value="all">Все</option>
                            </select>
                        </div>
                        <label className="col-sm-2 col-form-label ms-5">Номер финансового
                            счета:</label>
                        <div className="col-sm-2">
                            <input type="text" className="form-control"/>
                        </div>

                    </div>
                    <div className="form-group row mt-3">
                        <label className="col-sm-2 col-form-label">Тип:</label>
                        <div className="col-sm-4">
                            <select className="form-select">
                                <option value="all">Все</option>
                            </select>
                        </div>
                        <label className="col-sm-2 col-form-label ms-5">Электронные лицензии</label>
                        <div className="col-sm-2 align-self-center">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="LicenseCheck"/>
                            </div>
                        </div>

                    </div>
                </SearchContainer>
                <ButtonSearchSubmit name="Поиск" className="btn mt-2 btn_search"/>
            </form>
        </div>

    )
}
