import React, {useState, useEffect} from "react";
import {useAppSelector, useAppDispatch} from "../../../../hooks/ReduxHooks";
import {fetchAddresses} from "../../../../api/addresses";
import {fetchCountries} from "../../../../api/countries";
import { setCustomerInfo, toggleDropshipping, setOrderData, resetOrderShipping } from "../../../../store/slices/OrderShippingSlice";


export const OrderDeliveryInfo = () => {
    const dispatch = useAppDispatch();
    const {orderData} = useAppSelector(state => state.order);
    const { customerName, phone, email, city, postalCode, address, selectedAddress, selectedCountry, isDropshipping } = useAppSelector(state => state.orderShipping);
    const [addresses, setAddresses] = useState<{ id: string, display_name: string }[]>([]);
    const [countries, setCountries] = useState<{ id: string, display_name: string }[]>([]);

    const handleDropshippingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        dispatch(toggleDropshipping(checked));
    };

    const handleAddressChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        dispatch(setCustomerInfo({ selectedAddress: Number(selectedValue) }));
    };

    const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        dispatch(setCustomerInfo({ selectedCountry: Number(selectedValue) }));
    };

    useEffect(() => {
        const getAddresses = async () => {
            const result = await fetchAddresses();
            if (!result?.data?.data) return;
            setAddresses(result?.data?.data);
        };

        const getCountries = async () => {
            const result = await fetchCountries();
            if (!result?.data?.data) return;
            setCountries(result?.data?.data);
        };
        if (orderData?.is_dropshipping) {
            dispatch(setOrderData({ is_dropshipping: orderData?.is_dropshipping }));
        } else {
            dispatch(resetOrderShipping());
        }
        if (!orderData?.is_b2b_active) {
            getAddresses();
            getCountries();
        }
    }, [orderData, orderData?.is_b2b_active]);

    return ( orderData ?
        <>
            <div id="informations" className="row">
                <div id="dropshipping">
                    {!orderData?.is_reserve && orderData?.user_id?.is_dropshipping && (
                        <div className="form-check">
                            {orderData?.is_b2b_active ? (
                                <>
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="saleOrderDropshippingCheck"
                                        checked={orderData?.is_dropshipping}
                                        onChange={handleDropshippingChange}
                                        disabled={orderData?.is_b2b_active}
                                    />
                                </>
                            ) : (
                                <>
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="saleOrderDropshippingCheck"
                                        checked={isDropshipping}
                                        onChange={handleDropshippingChange}
                                        disabled={orderData?.is_b2b_active}
                                    />
                                </>
                            )}
                            <label className="form-check-label" htmlFor="saleOrderDropshippingCheck">
                                <b>Drop shipping</b>
                            </label>
                        </div>
                    )}
                </div>
                <div id="sale_info" className="col-12 col-lg-6 mb-4">
                    <table className="table table-borderless table-sm">
                        <tbody>
                        {orderData.is_reserve ? (
                            <>
                                <tr>
                                    <th className="w-100 ps-0 pb-1">Дата резерва:</th>
                                    <td className="w-100 pb-1 text-nowrap">
                                        <span>{orderData.date_order.split(" ")[0]}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="w-100 ps-0 pb-1">Срок резерва:</th>
                                    <td className="w-100 pb-1 text-nowrap">
                                        <span>{orderData.reservation_date.split(" ")[0]}</span>
                                    </td>
                                </tr>
                            </>
                        ) : (
                            <>
                                <tr>
                                    <th className="w-100 ps-0 pb-1">Дата заказа:</th>
                                    <td className="w-100 pb-1 text-nowrap">
                                        <span>{orderData.date_order.split(" ")[0]}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="w-100 ps-0 pb-1">Дата подтверждения:</th>
                                    <td className="w-100 pb-1 text-nowrap">
                                        <span>{orderData.date_order.split(" ")[0]}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="w-100 ps-0 pb-1">Дата отгрузки:</th>
                                    <td className="w-100 pb-1 text-nowrap">
                                        <span>{orderData.date_order.split(" ")[0]}</span>
                                    </td>
                                </tr>
                                {!orderData?.is_reserve && orderData?.user_id?.is_dropshipping  && !isDropshipping && (
                                    <tr>
                                        <th className="w-100 ps-0 pb-1">Адрес доставки:</th>
                                        <td className="w-100 pb-1 text-nowrap">
                                            {orderData?.is_b2b_active ? (
                                                <>
                                                    <span>{orderData?.delivery_address}</span>
                                                </>
                                            ) : (
                                                <>
                                                    <select
                                                        className="form-select form-select-sm"
                                                        value={selectedAddress}
                                                        onChange={handleAddressChange}
                                                        disabled={orderData?.is_b2b_active}
                                                    >
                                                        <option></option>
                                                        {addresses.map((address) => (
                                                            <option key={address.id} value={address.id}>
                                                                {address.display_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                )}
                            </>
                        )}
                        </tbody>
                    </table>
                </div>
                {!orderData?.is_reserve && orderData?.user_id?.is_dropshipping && isDropshipping && (
                    <div className="col-12 col-lg-6 mb-4">
                        <table className="table table-borderless table-sm">
                            <tbody>
                            <tr className="form-group">
                                <th>Имя покупателя:</th>
                                <td>
                                    {orderData?.is_b2b_active ? (
                                        <>
                                            <span>{orderData?.dropshipping_user?.name}</span>
                                        </>
                                    ) : (
                                        <>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                value={customerName}
                                                onChange={(e) => dispatch(setCustomerInfo({customerName: e.target.value}))}
                                            />
                                        </>
                                    )
                                    }
                                </td>
                            </tr>
                            <tr className="form-group">
                                <th>Телефон:</th>
                                <td>
                                    {orderData?.is_b2b_active ? (
                                        <>
                                            <span>{orderData?.dropshipping_user?.phone}</span>
                                        </>
                                    ) : (
                                        <>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                value={phone}
                                                onChange={(e) => dispatch(setCustomerInfo({phone: e.target.value}))}
                                            />
                                        </>
                                    )
                                    }
                                </td>
                            </tr>
                            <tr className="form-group">
                                <th>Email:</th>
                                <td>
                                    {orderData?.is_b2b_active ? (
                                        <>
                                            <span>{orderData?.dropshipping_user?.email}</span>
                                        </>
                                    ) : (
                                        <>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                value={email}
                                                onChange={(e) => dispatch(setCustomerInfo({email: e.target.value}))}
                                            />
                                        </>
                                    )
                                    }
                                </td>
                            </tr>
                            <tr className="form-group">
                                <th>Город:</th>
                                <td>
                                    {orderData?.is_b2b_active ? (
                                        <>
                                            <span>{orderData?.dropshipping_user?.city}</span>
                                        </>
                                    ) : (
                                        <>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                value={city}
                                                onChange={(e) => dispatch(setCustomerInfo({city: e.target.value}))}
                                            />
                                        </>
                                    )
                                    }
                                </td>
                            </tr>
                            <tr className="form-group">
                                <th>Индекс:</th>
                                <td>
                                    {orderData?.is_b2b_active ? (
                                        <>
                                            <span>{orderData?.dropshipping_user?.postal_code}</span>
                                        </>
                                    ) : (
                                        <>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                value={postalCode}
                                                onChange={(e) => dispatch(setCustomerInfo({postalCode: e.target.value}))}
                                            />
                                        </>
                                    )
                                    }
                                </td>
                            </tr>
                            <tr className="form-group">
                                <th>Адрес:</th>
                                <td>
                                    {orderData?.is_b2b_active ? (
                                        <>
                                            <span>{orderData?.dropshipping_user?.address}</span>
                                        </>
                                    ) : (
                                        <>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                value={address}
                                                onChange={(e) => dispatch(setCustomerInfo({address: e.target.value}))}
                                            />
                                        </>
                                    )
                                    }
                                </td>
                            </tr>
                            <tr className="form-group">
                                <th>Страна:</th>
                                <td>
                                    {orderData?.is_b2b_active ? (
                                        <>
                                            <span>{orderData?.dropshipping_user?.country}</span>
                                        </>
                                    ) : (
                                        <>
                                            <select
                                                className="form-select form-select-sm"
                                                value={selectedCountry}
                                                onChange={handleCountryChange}
                                                disabled={orderData?.is_b2b_active}
                                            >
                                                <option></option>
                                                {countries.map((country) => (
                                                    <option key={country.id} value={country.id}>
                                                        {country.display_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </>
                                    )
                                    }
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </> : <></>
    )
}

