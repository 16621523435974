import {Navigation, Pagination, Scrollbar, A11y} from "swiper/modules";

import {Swiper, SwiperSlide} from "swiper/react";


import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import 'swiper/css/pagination';
import {useState} from "react";
import {SwiperImage} from "./styles";

import "./SiwperProduct.sass"

interface SwiperProductProps {
    imageList?: string[]
}


export const SwiperProduct = (props: SwiperProductProps) => {
    const [swiperRef, setSwiperRef] = useState<typeof Swiper | any>({});
    const imageList = props.imageList || [
        "https://imgproxy.onliner.by/yM7qZT2If5T_0jjYcYqGzf7ixrHLQwydZfHa-pX_xes/w:700/h:550/f:jpg/aHR0cHM6Ly9jb250/ZW50Lm9ubGluZXIu/YnkvY2F0YWxvZy9k/ZXZpY2UvbGFyZ2Uv/ZGZiNmMxZDZkMjg1/MTcxMTA0MDU2NDY0/N2MzNGZiZGMuanBn",
        "https://imgproxy.onliner.by/0LdBn9ZIavFqnYK1joPyys7efuef5jFLth2On2GkDvc/w:700/h:550/f:jpg/aHR0cHM6Ly9jb250/ZW50Lm9ubGluZXIu/YnkvY2F0YWxvZy9k/ZXZpY2UvbGFyZ2Uv/YzA3ZTY2NWE5NDM1/YzhlY2E5YTk1ZWZh/ZTQ0M2I4YTQuanBn",
        "https://imgproxy.onliner.by/18bWsTn8nnZw9mnrz64HCquC4RvG8qa3vwL6PUrQpxQ/w:700/h:550/f:jpg/aHR0cHM6Ly9jb250/ZW50Lm9ubGluZXIu/YnkvY2F0YWxvZy9k/ZXZpY2UvbGFyZ2Uv/YjExNmVhMDZkNGVj/NGY5NGEyMTYzYjUx/NTFlNTljZTguanBn",
        "https://imgproxy.onliner.by/h16qV4IBk1he0doaee3ODLSs5WJk1P6cJKcw00bs2h8/w:700/h:550/f:jpg/aHR0cHM6Ly9jb250/ZW50Lm9ubGluZXIu/YnkvY2F0YWxvZy9k/ZXZpY2UvbGFyZ2Uv/ZjczZmEyOTJkYmQ2/MmI1MGVkN2ZmNDg1/MjJhODJmMTcuanBn",
        "https://imgproxy.onliner.by/7_1I-VJqlOYZYhzmKS9RQkPRUYhrQ6Cdp-FyTm3Qzmc/w:700/h:550/f:jpg/aHR0cHM6Ly9jb250/ZW50Lm9ubGluZXIu/YnkvY2F0YWxvZy9k/ZXZpY2UvbGFyZ2Uv/YzAwYzc1ZGFlMGFm/YTJhOTIxZjc4Zjc3/ZTIxYWZmMmQuanBn",
        "https://imgproxy.onliner.by/qXxEkHpaaMtoR9g_WwKp7sCLlquuUzeetyz4n32qKuE/w:700/h:550/f:jpg/aHR0cHM6Ly9jb250/ZW50Lm9ubGluZXIu/YnkvY2F0YWxvZy9k/ZXZpY2UvbGFyZ2Uv/MWE3YTRjYmI4Njk4/ZjU1OTMxMDU4MTdh/MmEyYzhmNWUuanBn",
    ]
    const [activeImage, setActiveImage] = useState(imageList[0]);

    const handleSlideChange = (swiper: any) => {
        setActiveImage(imageList[swiper.activeIndex]);
    };

    const handleSlideClick = (index: number) => {
        if (!swiperRef) return
        swiperRef.slideTo(index)
    };

    return (
        <>
            <div className="product__image-wrapper" draggable={false}>
                <img loading="lazy" className="product__image" draggable={false} src={activeImage}/>
            </div>
            <Swiper
                onSlideChange={handleSlideChange}
                onSwiper={setSwiperRef}
                slidesPerView={2}
                centeredSlides={true}
                spaceBetween={2}
                navigation={true}
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                className="prductSwiper"
                width={"50px"}
            >
                {imageList.map((img_url, index) => {
                    return <SwiperSlide
                            key={index}
                            draggable={false}
                            className={"product__swiper_slide"}
                            onClick={() => handleSlideClick(index)}
                        >
                        <SwiperImage
                            className={"product__swiper_img"}
                            loading={"lazy"}
                            src={img_url}
                        />
                    </SwiperSlide>
                })}
            </Swiper>

        </>
    );
};