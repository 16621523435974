import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchProductsRequests} from "../../api/catalogProducts";

export type initialStateType = {
    pageNumber: number;
    pagesCount: number;
    isProductLoading: boolean;
    productsCount: number;
    maxProductCount: number;
    productList: any[];
    productInfo: any[]
}

const initialState: initialStateType = {
    productsCount: 0,
    maxProductCount: 0,
    productInfo: [],
    pageNumber: 0,
    pagesCount: 0,
    isProductLoading: true,
    productList: [],
}

export const fetchCatalogProducts =
    createAsyncThunk("slice/catalogProductsAdd",
        async (values: any) => {
            const {pageNum, categoryId, searchValue, activeFilters} = values
            const result = await fetchProductsRequests(pageNum, categoryId, searchValue, activeFilters)
            return result.data?.data
        }
    )

export const extendCatalogProducts =
    createAsyncThunk("slice/catalogProductsExtend",
        async (values: any) => {
            const {pageNum, categoryId, searchValue, activeFilters} = values
            const result = await fetchProductsRequests(pageNum, categoryId, searchValue, activeFilters)
            return result.data?.data
        }
    )

export const resetCatalogProducts =
    createAsyncThunk("slice/catalogProductsReset",
        async () => initialState
    )

const catalogProductsSliceReducer = createSlice({
    name: "catalogProducts",
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(fetchCatalogProducts.fulfilled, (state, action) => {
            state.productsCount = action?.payload?.product_count;
            state.maxProductCount = action?.payload?.max_product_count;
            state.productInfo = action?.payload?.product_info;
            state.pageNumber = action?.payload?.page_number;
            state.pagesCount = action?.payload?.pages_count;
            state.productList = action?.payload?.product_list;
            state.isProductLoading = false
        }).addCase(extendCatalogProducts.fulfilled, (state, action) => {
            state.productsCount = action?.payload?.product_count;
            state.maxProductCount = action?.payload?.max_product_count;
            state.productInfo = state.productInfo ? state.productInfo.concat(action?.payload?.product_info) : action?.payload?.product_info;
            state.pageNumber = action?.payload?.page_number;
            state.pagesCount = action?.payload?.pages_count;
            state.productList = action?.payload?.product_list;
            state.isProductLoading = false;
        }).addCase(resetCatalogProducts.fulfilled, (state, action) => {
            Object.assign(state, initialState);
            state.isProductLoading = false;

        })
    }
})

export default catalogProductsSliceReducer.reducer