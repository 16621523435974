// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tradeCartContainer {
  width: 100%;
}

.tradeCartFilter {
  margin-top: 20px;
  font-weight: 600;
  font-size: 11pt;
}
.tradeCartFilter select {
  color: rgb(110, 209, 243);
}

.tradeCartProductCount {
  font-weight: 600;
  font-size: 10pt;
}

.tradeCartControlPanel {
  display: flex;
  justify-content: space-between;
}
.tradeCartControlPanel__buttons {
  display: flex;
  flex: 1 0 55%;
  gap: 5px;
  height: -moz-fit-content;
  height: fit-content;
  align-self: end;
  justify-content: end;
}
.tradeCartControlPanel__buttons button {
  border-color: rgb(110, 209, 243) !important;
}

.tradeCartTotal {
  display: flex;
  margin-top: 100px;
  justify-content: space-between;
}
.tradeCartTotal__bill {
  width: 100%;
  max-width: 450px;
}
.tradeCartTotal__buttons {
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  gap: 20px;
}
.tradeCartTotal__buttons button {
  border-color: rgb(110, 209, 243);
}
.tradeCartTotal button {
  padding: 12px 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/cartPage/CartPage.sass"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AACA;EACE,gBAAA;EACA,gBAAA;EACA,eAAA;AAEF;AADE;EACE,yBAAA;AAGJ;;AADA;EACE,gBAAA;EACA,eAAA;AAIF;;AAFA;EACE,aAAA;EACA,8BAAA;AAKF;AAHE;EACE,aAAA;EACA,aAAA;EACA,QAAA;EACA,wBAAA;EAAA,mBAAA;EACA,eAAA;EACA,oBAAA;AAKJ;AAHI;EACE,2CAAA;AAKN;;AAHA;EACE,aAAA;EACA,iBAAA;EACA,8BAAA;AAMF;AAJE;EACE,WAAA;EACA,gBAAA;AAMJ;AAJE;EACE,wBAAA;EAAA,mBAAA;EACA,aAAA;EACA,SAAA;AAMJ;AAJI;EACE,gCAAA;AAMN;AAJE;EACE,kBAAA;AAMJ","sourcesContent":[".tradeCartContainer\n  width: 100%\n\n.tradeCartFilter\n  margin-top: 20px\n  font-weight: 600\n  font-size: 11pt\n  select\n    color: rgb(110, 209, 243)\n\n.tradeCartProductCount\n  font-weight: 600\n  font-size: 10pt\n\n.tradeCartControlPanel\n  display: flex\n  justify-content: space-between\n\n  &__buttons\n    display: flex\n    flex: 1 0 55%\n    gap: 5px\n    height: fit-content\n    align-self: end\n    justify-content: end\n\n    button\n      border-color: rgb(110, 209, 243) !important\n\n.tradeCartTotal\n  display: flex\n  margin-top: 100px\n  justify-content: space-between\n\n  &__bill\n    width: 100%\n    max-width: 450px\n\n  &__buttons\n    height: fit-content\n    display: flex\n    gap: 20px\n\n    button\n      border-color: rgb(110, 209, 243)\n\n  button\n    padding: 12px 20px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
