import styled from "styled-components";


export const CatalogFilterWrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
`;

export const CatalogPage = styled.div`
    height: 100%;
`;

export const CatalogItemsCount = styled.span`
    margin: auto;
    vertical-align: middle;
    text-wrap: nowrap;
    align-items: end;
`;
