import React from "react";
import {useAppSelector} from "../../../../hooks/ReduxHooks";

export const OrderDeliveryInfo = () => {
    const {orderData} = useAppSelector(state => state.order)

    return ( orderData ?
        <>
            <div id="informations" className="row">
                <div id="sale_info" className="col-12 col-lg-6 mb-4">
                    <table className="table table-borderless table-sm">
                        <tbody>
                        {orderData.is_reserve ? (
                            <>
                                <tr>
                                    <th className="w-100 ps-0 pb-0">Дата резерва:</th>
                                    <td className="w-100 pb-0 text-nowrap">
                                    <span>{orderData.date_order.split(" ")[0]}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="w-100 ps-0 pb-0">Срок резерва:</th>
                                    <td className="w-100 pb-0 text-nowrap">
                                        <span>{orderData.reservation_date.split(" ")[0]}</span>
                                    </td>
                                </tr>
                            </>
                        ) : (
                            <>
                                <tr>
                                    <th className="w-100 ps-0 pb-0">Дата заказа:</th>
                                    <td className="w-100 pb-0 text-nowrap">
                                        <span>{orderData.date_order.split(" ")[0]}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="w-100 ps-0 pb-0">Дата подтверждения:</th>
                                    <td className="w-100 pb-0 text-nowrap">
                                        <span>{orderData.date_order.split(" ")[0]}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="w-100 ps-0 pb-0">Дата отгрузки:</th>
                                    <td className="w-100 pb-0 text-nowrap">
                                        <span>{orderData.date_order.split(" ")[0]}</span>
                                    </td>
                                </tr>
                            </>
                            )}

                        </tbody>
                    </table>
                </div>
            </div>
        </> : <></>
    )
}