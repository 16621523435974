import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {DATE_RANGE} from "../../../constants/consts";
import {BarChart} from "@mui/x-charts/BarChart";
import * as React from "react";
import FormControl from "@mui/material/FormControl";
import "./TransactionsBarChart.sass"
import {Loader} from "../../loader/Loader";

interface TransactionsBarChartProps {
    groupBy: string;
    label: string;
    isLoading: boolean;
    dataset: any[]
    handleChangeGroupBy: (event: SelectChangeEvent) => void
}


export const TransactionsBarChart = (props: TransactionsBarChartProps) => {

    const {groupBy, dataset, handleChangeGroupBy, label, isLoading} = props

    const graphSize = {
        width: 400,
        height: 300,
    }
    const chartSetting = {
        ...graphSize,
        xAxis: [
            {
                label: "Сумма",
            },
        ],

    };

    const valueFormatter = (value: number | null) => {
        return `${Number(value).toFixed(2)}`
    };

    return (

        <div className="chart__wrapper">
            <h5 className="w-100 text-center">{label}</h5>
            <div className="chart__select">
                <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                    <Select
                        labelId="groupBy-select-small-label"
                        id="groupBy-select-small"
                        value={groupBy}
                        label="Период"
                        onChange={handleChangeGroupBy}
                        sx={{fontSize: "12px"}}
                    >
                        <MenuItem value={DATE_RANGE.DAY}>День</MenuItem>
                        <MenuItem value={DATE_RANGE.MONTH}>Месяц</MenuItem>
                        <MenuItem value={DATE_RANGE.YEAR}>Год</MenuItem>
                    </Select>
                </FormControl>
            </div>
            {isLoading ? (
                <div style={graphSize}><Loader/></div>
            ) : (
                <BarChart
                    dataset={dataset}
                    yAxis={[{scaleType: "band", dataKey: "date"}]}
                    series={[{dataKey: "total", color: "rgb(110, 209, 243)", valueFormatter}]}
                    layout="horizontal"
                    {...chartSetting}
                    margin={{left: 100}}
                />
            )}

        </div>

    );
}