import styled from "styled-components";

export const SearchPanelBaseContainer = styled.div`
    display: flex;
    align-items: center;

`;


export const SearchPanelFormInput = styled.input`
    border: 1px solid #DEE2E6;
    box-shadow: none;

`

export const SearchPanelCol = styled.div`
    width: 100%;
    :focus{
        border: 1px solid #DEE2E6;
        box-shadow: none;
    }

`

export const SearchPanelFormButton = styled.button`
    border: 1px solid #DEE2E6 !important;
    :hover {
        border-right: none;
    }

`

export const SearchPanelInputGroup = styled.div`
    flex-wrap: nowrap;
    :hover {
    }
`