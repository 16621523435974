import {FC, useEffect, useState} from "react";
import { useAuth } from "../../hooks/UseAuth";
import { ButtonCustom, Container } from "./styles";
import {COUNTRIES, ROUTE_URLS, USER_ROLES} from "../../constants/consts";
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import {
    checkUserRole, clearLocalStorage, getIsTwoFactorAuthorized,
    getSession,
    getSessionUserCompany,
    getSessionUserRole, getTwoFactorEnabled, setSession, setSessionUserCompany,
    setSessionUserRole, setUserEmail
} from "../../api/sessionManagement";
import "./Form.sass";
import Box from "@mui/material/Box";
import PersonIcon from "@mui/icons-material/Person";
import Typography from "@mui/material/Typography";
import {Link, useNavigate} from "react-router-dom";
import {Card, CardContent,Button} from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';

interface FormProps {
    title: string;
    isSignup?: boolean;
    handleClick: (
        email: string, password: string, region: string, companyName: string, vat: string, phone: string, registration: string, zip: string, city: string, address: string, owners: string[], boards: string[]
    ) => void;
}

export const Form: FC<FormProps> = ({ title, handleClick, isSignup }) => {
    const {
        email,
        password,
        zip,
        city,
        address,
        boards,
        owners,
        region,
        companyName,
        vat,
        phone,
        registration,
        handleChangeZip,
        handleChangeBoard,
        handleAddBoard,
        handleRemoveBoard,
        handleChangeCity,
        handleChangeAddress,
        handleChangeOwner,
        handleAddOwner,
        handleRemoveOwner,
        handleChangeEmail,
        handleChangePassword,
        handleChangeRegion,
        handleChangeCompanyName,
        handleChangeVat,
        handleChangePhone,
        handleChangeRegistration,
        handleAuth,
    } = useAuth({ handleClick });

    const [isActiveSession, setIsActiveSession] = useState<boolean>(false)

    const handleClickSubmitAuth = (event: any) => {
        event.preventDefault();
        handleAuth();
    };

    useEffect(() => {
        if (getSession()){
            setIsActiveSession(true)
        }
        else {
            setIsActiveSession(false)
        }
    }, []);

    const navigate = useNavigate();

    const textFieldStyles = {
        transition: 'width 0.3s ease-in-out',
        width: '100%', // Стандартная ширина
        '& .MuiOutlinedInput-root': {
            borderRadius: '10px', // Добавление скругленных углов для соответствия макету
            backgroundColor: '#ebebeb', // Цвет фона
            padding:"0 10px",
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                border: 'none',
                borderColor: 'gray',
            },
            '&.Mui-focused fieldset': {
                border: 'none',
            },
        },
        '&:focus-within': {
            width: "calc(100% + 20px)",
        }
    }

    const inputPropsStyles = {
        '&::placeholder': {
            color: '#757575',
            fontWeight: 'bold',
        },
        '& .MuiInputBase-input:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px #ebebeb inset',
            WebkitTextFillColor: '#000000',
            transition: 'background-color 5000s ease-in-out 0s',
        },
        '& .MuiInputBase-input': {
            color: '#000000',
            fontWeight: 'bold',
        },
    };

    const inputLabelPropsStyles = {
        color: '#757575',
        fontWeight: 'bold',
        '&.Mui-focused': {
            color: '#757575',
        },
    };

    const formControlStyles = {
        width: '100%',
        '& .MuiOutlinedInput-root': {
            borderRadius: '10px',
            backgroundColor: '#ebebeb',
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                border: 'none',
                borderColor: 'gray',
            },
            '&.Mui-focused fieldset': {
                border: 'none',
            },
        },
    };

    const selectStyles = {
        fontWeight: 'bold',
        color: '#000000', // Цвет текста выбора
        '&:focus': {
            backgroundColor: '#ebebeb',
        },
    };

    const handleBannerClick = async () => {
        const isTwoFactorAuthorized = getIsTwoFactorAuthorized();
        const isTwoFactorEnabled = getTwoFactorEnabled();
        let result = await checkUserRole()
        if (!result.error) {
            setSession(result.data.data.session);
            setSessionUserRole(result.data.data.role);
            let userRole:any = getSessionUserRole()
            if (isTwoFactorEnabled && !isTwoFactorAuthorized && result.data.data.is_need_verify) {
                navigate(ROUTE_URLS.TWO_FACTOR_AUTH);
            } else if (userRole && [USER_ROLES.CONFIRMED,USER_ROLES.WAITING].includes(userRole)) {
                navigate(ROUTE_URLS.QUESTIONARY);
            }
            else {
                navigate(ROUTE_URLS.HOME);
            }
        }
    };

    const handleRegionChange = (event: any) => {
        handleChangeRegion(event);
    };

    const handleLogoutClick = () => {
        clearLocalStorage()
        setIsActiveSession(false)
    }


    return (
        <>
            {
                isActiveSession ? <Container>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px', // Space between card and button
                            marginTop: '5px',
                        }}
                    >
                        <Card
                            onClick={handleBannerClick}
                            sx={{
                                display: 'flex',
                                flex:'1',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#ffffff',
                                padding: '20px',
                                borderRadius: '12px',
                                boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.35)',
                                marginTop: '5px',
                                cursor: 'pointer',
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                '&:hover': {
                                    transform: 'translateY(-5px)',
                                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.35)',
                                },
                            }}
                        >
                            <CardContent
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: 0,
                                    paddingBottom: "0 !important",
                                }}
                            >
                                <PersonIcon sx={{ fontSize: '40px', color: 'rgb(110, 209, 243)', marginRight: '10px' }} />
                                <Box>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: 'bold',
                                            color: '#333',
                                            textShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)',
                                        }}
                                    >
                                        {getSessionUserCompany()}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: '#555',
                                            fontStyle: 'italic',
                                        }}
                                    >
                                        У вас уже есть аккаунт
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleLogoutClick}
                            sx={{
                                height: 'auto', // Ensure button height matches card
                                flexShrink: 0, // Prevent button from shrinking
                                display: 'flex',
                                color:'white',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '5px',
                                padding: '19px 10px',
                                borderRadius: '12px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                backgroundColor: 'rgb(110, 209, 243)',
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                '&:hover': {
                                    backgroundColor: 'rgb(110, 209, 243)',
                                    transform: 'translateY(-5px)',
                                    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
                                },
                            }}
                        >
                            <LogoutIcon sx={{ fontSize: '30px', color: '#fff' }} />
                            <Typography variant="button" sx={{ color: '#fff', fontWeight: 'bold' }}>
                                Выйти
                            </Typography>
                        </Button>
                    </Box>
                    </Container>
                    :<>
                    <Container onSubmit={handleClickSubmitAuth}>
                        {isSignup && (
                            <>
                                <TextField
                                    value={companyName}
                                    required
                                    className="auth-input"
                                    label="Company legal name"
                                    onChange={handleChangeCompanyName}
                                    sx={textFieldStyles}
                                    autoComplete="off"
                                    InputProps={{
                                        sx: inputPropsStyles,
                                    }}
                                    InputLabelProps={{
                                        sx: inputLabelPropsStyles,
                                    }}
                                />
                                <FormControl variant="outlined" sx={formControlStyles} required>
                                    <InputLabel sx={inputLabelPropsStyles}>Select a region</InputLabel>
                                    <Select
                                        id="region"
                                        required
                                        name="region"
                                        value={region}
                                        onChange={handleRegionChange}
                                        label="Select a region"
                                        sx={selectStyles}
                                        inputProps={{
                                            sx: inputPropsStyles,
                                        }}
                                    >
                                        <MenuItem value="" disabled>
                                            Select a region
                                        </MenuItem>
                                        {Object.entries(COUNTRIES).map(([code, name]) => (
                                            <MenuItem key={code} value={code}>
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField
                                    value={registration}
                                    required
                                    className="auth-input"
                                    autoComplete="off"
                                    label="Registration NO."
                                    onChange={handleChangeRegistration}
                                    sx={textFieldStyles}
                                    InputProps={{
                                        sx: inputPropsStyles,
                                    }}
                                    InputLabelProps={{
                                        sx: inputLabelPropsStyles,
                                    }}
                                />
                                <TextField
                                    value={vat}
                                    required
                                    className="auth-input"
                                    label="VAT NO."
                                    autoComplete="off"
                                    onChange={handleChangeVat}
                                    sx={textFieldStyles}
                                    InputProps={{
                                        sx: inputPropsStyles,
                                    }}
                                    InputLabelProps={{
                                        sx: inputLabelPropsStyles,
                                    }}
                                />
                                <TextField
                                    value={phone}
                                    required
                                    autoComplete="off"
                                    className="auth-input"
                                    label="Contact Phone Number"
                                    onChange={handleChangePhone}
                                    sx={textFieldStyles}
                                    InputProps={{
                                        sx: inputPropsStyles,
                                    }}
                                    InputLabelProps={{
                                        sx: inputLabelPropsStyles,
                                    }}
                                />
                                <TextField
                                    value={zip}
                                    required
                                    autoComplete="off"
                                    className="auth-input"
                                    label="Zip"
                                    onChange={handleChangeZip}
                                    sx={textFieldStyles}
                                    InputProps={{
                                        sx: inputPropsStyles,
                                    }}
                                    InputLabelProps={{
                                        sx: inputLabelPropsStyles,
                                    }}
                                />
                                <TextField
                                    value={city}
                                    required
                                    autoComplete="off"
                                    className="auth-input"
                                    label="City"
                                    onChange={handleChangeCity}
                                    sx={textFieldStyles}
                                    InputProps={{
                                        sx: inputPropsStyles,
                                    }}
                                    InputLabelProps={{
                                        sx: inputLabelPropsStyles,
                                    }}
                                />
                                <TextField
                                    value={address}
                                    required
                                    autoComplete="off"
                                    className="auth-input"
                                    label="Address"
                                    onChange={handleChangeAddress}
                                    sx={textFieldStyles}
                                    InputProps={{
                                        sx: inputPropsStyles,
                                    }}
                                    InputLabelProps={{
                                        sx: inputLabelPropsStyles,
                                    }}
                                />
                                {boards.map((board: any, index: any) => (
                                    <>
                                        <TextField
                                            value={board}
                                            required
                                            autoComplete="off"
                                            className="auth-input"
                                            onChange={(event: any) => handleChangeBoard(index, event)}
                                            label="Company board"
                                            sx={textFieldStyles}
                                            InputProps={{
                                                endAdornment: (
                                                    boards.length > 1 && (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => handleRemoveBoard(index)}
                                                                size="small"
                                                                edge="end"
                                                                aria-label="delete"
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                ),
                                                sx: inputPropsStyles,
                                            }}
                                            InputLabelProps={{
                                                sx: inputLabelPropsStyles,
                                            }}
                                        />
                                    </>
                                ))}
                                <button className="buttonAdd" type="button" onClick={handleAddBoard}>+ Add board</button>
                                {owners.map((owner, index) => (
                                    <>
                                        <TextField
                                            value={owner}
                                            required
                                            autoComplete="off"
                                            className="auth-input"
                                            onChange={(event:any) => handleChangeOwner(index, event)}
                                            label="Beneficiary owner"
                                            sx={textFieldStyles}
                                            InputProps={{
                                                endAdornment: (
                                                    owners.length > 1 && (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => handleRemoveOwner(index)}
                                                                size="small"
                                                                edge="end"
                                                                aria-label="delete"
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                ),
                                                sx: inputPropsStyles,
                                            }}
                                            InputLabelProps={{
                                                sx: inputLabelPropsStyles,
                                            }}
                                        />
                                    </>
                                ))}
                                <button className="buttonAdd" type="button" onClick={handleAddOwner}>+ Add Owner</button>
                            </>
                        )}
                        <TextField
                            value={email}
                            required
                            type="email"
                            className="auth-input"
                            label="Email"
                            onChange={handleChangeEmail}
                            sx={textFieldStyles}
                            autoComplete="off"
                            InputProps={{
                                sx: inputPropsStyles,
                            }}
                            InputLabelProps={{
                                sx: inputLabelPropsStyles,
                            }}
                        />
                        {!isSignup && (
                            <TextField
                                value={password}
                                required
                                type="password"
                                className="auth-input"
                                label="Password"
                                onChange={handleChangePassword}
                                sx={textFieldStyles}
                                autoComplete="off"
                                InputProps={{
                                    sx: inputPropsStyles,
                                }}
                                InputLabelProps={{
                                    sx: inputLabelPropsStyles,
                                }}
                            />
                        )}
                        <ButtonCustom type="submit">
                            {title}
                        </ButtonCustom>
                    </Container>
                        {isSignup && <p>Есть аккаунт? <Link to="/sign-in">Войти</Link></p>}
                        {!isSignup && <p style={{marginTop: "10px"}}>Или <Link to="/sign-up">зарегистрироваться</Link></p>}
                    </>
            }

        </>
    );
};
