import axios from "axios";
import { resolve } from "./resolve.js";
import {API_BACKEND_URL, DATE_RANGE} from "../constants/consts";
import {getSession} from "./sessionManagement";


export async function fetchOrdersRequests(pageNum: number, searchValue: string | undefined, isReserve = false, isDaily = false, isActive: boolean | undefined = undefined) {
    const sessionId = getSession()
    const searchUrlPart = searchValue ? `?filter=["name", "ilike", "${searchValue}"]` : ""
    let additionalParams = `?is_reserve=${isReserve}&is_daily=${isDaily}`
    if (isActive !== undefined) {
        additionalParams += `&is_active=${isActive}`
    }
    const URL = `${API_BACKEND_URL}/sales/page/${pageNum}${searchUrlPart}${additionalParams}`
    return await resolve(axios.get(URL,
            {
                headers: {
                    "Content-Type": "application/json",
                    "session_id": sessionId,
                }
            }
        ).then(res => res)
    );
}

export async function fetchOrdersHistory(groupby: DATE_RANGE) {
    const sessionId = getSession()
    const URL = `${API_BACKEND_URL}/sales/get_sale_history`
    return await resolve(axios.post(URL,
        {groupby: groupby || "day"},
        {
            headers: {
                "Content-Type": "application/json",
                "session_id": sessionId,
            }
        }
    ).then(res => res)
    );
}

export async function fetchTopSaleItems() {
    const sessionId = getSession()
    const URL = `${API_BACKEND_URL}/sales/get_top_sale_items`
    return await resolve(axios.post(URL,
        {},
        {
            headers: {
                "Content-Type": "application/json",
                "session_id": sessionId,
            }
        }
    ).then(res => res)
    );
}

export async function updateOrderLines(data: { id_order: number; line_data: { product_id: number; quantity: any; }[] | { product_id: any; quantity: any; }[] | { id: number; quantity: number; requested_qty?: number | undefined; }[]; }){
    if (!data) return
    return await resolve(axios.post(`${API_BACKEND_URL}/sales/update_lines`,
        data,{
            headers: {
                "Content-Type": "application/json",
                "session_id": getSession(),
            }
        }

    ).then(res => res));
}

export async function deleteOrderLines(data: { ids: any[] | number[]; }){
    if (!data) return
    return await resolve(axios.post(`${API_BACKEND_URL}/sales/delete_lines`,
        data,{
            headers: {
                "Content-Type": "application/json",
                "session_id": getSession(),
            }
        }

    ).then(res => res));
}

export async function fetchUpdateOrder(saleId: number, data: { state?: string; is_b2b_active?: boolean; reservation_name?: any; }){
    if (!data) return
    return await resolve(axios.patch(`${API_BACKEND_URL}/sales/update_order?sale_id=${saleId}`,
        data,{
            headers: {
                "Content-Type": "application/json",
                "session_id": getSession(),
            }
        }

    ).then(res => res));
}


export async function fetchSingleOrderRequests(saleId: number) {
    const sessionId = getSession()
    const URL = `${API_BACKEND_URL}/sales/${saleId}`
    return await resolve(axios.get(URL,
            {
                headers: {
                    "Content-Type": "application/json",
                    "session_id": sessionId,
                }
            }
        ).then(res => res)
    );
}

export async function fetchReturnOrderLines(data: any) {
    const sessionId = getSession()
    const URL = `${API_BACKEND_URL}/sales/get_warranty_lines`
    return await resolve(axios.post(URL,
            data,{
                headers: {
                    "Content-Type": "application/json",
                    "session_id": sessionId,
                }
            }
        ).then(res => res)
    );
}

export async function fetchOrderLinesPaginated(pageNum: number, isReserve=false) {
    const sessionId = getSession()
    const additionalParams = `?is_reserve=${isReserve}`
    const URL = `${API_BACKEND_URL}/sales/sale_lines/page/${pageNum}${additionalParams}`
    return await resolve(axios.get(URL,
        {
            headers: {
                "Content-Type": "application/json",
                "session_id": sessionId,
            }
        }
    ).then(res => res)
    );
}
