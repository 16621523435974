import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {CartTableTr} from "./cartTableTr";


import "./CartTable.sass";
import {useAppSelector} from "../../hooks/ReduxHooks";

interface CartTableProps {
    cartProductLines: any;
    changeProductCountCart:any;
    setTotalCartBillInfo:any;
    clearCartLines: any;
}

interface TableRef {
    getActiveProductLines: () => any;
    setIsAllCheckBoxChecked: (newState: any) => void;
    setCheckedItems: (newState: any) => void;
}

export const CartTable = forwardRef<TableRef, CartTableProps>((props,ref) => {

    const [checkedItems, setCheckedItems] = useState<any>([]);
    const [isAllCheckBoxChecked, setIsAllCheckBoxChecked] = useState(false);
    const {activeCartData } = useAppSelector(state => state.activeCart)


    useImperativeHandle(ref, () => ({
        getActiveProductLines() {
            return checkedItems;
        },
        setIsAllCheckBoxChecked: (newState:any) => setIsAllCheckBoxChecked(newState),
        setCheckedItems: (newState:any) => setCheckedItems(newState),
    }));


    const clearCartLines = (linesIds:Array<number>) =>{
        props.clearCartLines(linesIds)
        handleCheckboxChange(linesIds[0],true)
    }

    const handleHeaderCheckBoxChange = () => {
        if (!activeCartData) return
        if (isAllCheckBoxChecked) {
            setCheckedItems([]);
            props.changeProductCountCart(0)

        } else {
            setCheckedItems(activeCartData.cart_lines.map((item:any) => item.id));
            props.changeProductCountCart(activeCartData.cart_lines.length)
        }
        setIsAllCheckBoxChecked(!isAllCheckBoxChecked);

    }

    const handleCheckboxChange = (id:number,isDelete=false) => {
        if (!activeCartData) return
        let newCheckedItems;
        let productCartLen = activeCartData.cart_lines.length
        if (isDelete && isChecked(id)){
            productCartLen-=1
        }
        if (checkedItems.includes(id)) {
            newCheckedItems = checkedItems.filter((itemId:any) => itemId !== id);
        } else {
            if (isDelete) {
                productCartLen-=1
                newCheckedItems = [...checkedItems]
            }
            else {
                newCheckedItems = [...checkedItems, id];
            }

        }
        setCheckedItems(newCheckedItems);
        if (newCheckedItems && newCheckedItems.length > 0) {
            setIsAllCheckBoxChecked(newCheckedItems.length === productCartLen);
            props.changeProductCountCart(newCheckedItems.length)
        }

    };

    const isChecked = (id:any) => checkedItems.includes(id);

    return (
        <table className="table cartTable">
            <thead className="cartTable__header">
                <tr>
                    <th scope="col">
                        <input checked={isAllCheckBoxChecked}  type="checkbox" value="" id="AllCartProductsCheck" onChange={handleHeaderCheckBoxChange}/>
                    </th>
                    <th scope="col" className="text-colored text-center text-nowrap">№</th>
                    <th scope="col" className="text-colored text-center text-nowrap">Фото</th>
                    <th scope="col" className="text-colored text-start text-nowrap">Код</th>
                    <th scope="col" className="text-colored text-start text-nowrap">Код производителя</th>
                    <th scope="col" className="text-colored text-start text-nowrap"></th>
                    <th scope="col" className="text-colored text-start text-nowrap">Название продукта</th>
                    <th scope="col" className="text-colored text-start text-nowrap">Количество</th>
                    <th scope="col" className="text-colored text-start text-nowrap">Цена <br/>без НДС {activeCartData?.currency?.symbol}</th>
                    <th scope="col" className="text-colored text-start text-nowrap">Сумма с<br/>HДC {activeCartData?.currency?.symbol}</th>
                    <th scope="col" className="text-colored text-start text-nowrap"></th>
                </tr>
            </thead>
            <tbody className="cartTable__body">
            {activeCartData && activeCartData.cart_lines.map((product:any,index:number) =>
                <CartTableTr
                    key={product.id}
                    clearCartLines ={clearCartLines}
                    setTotalCartBillInfo={props.setTotalCartBillInfo}
                    indexLine={index}
                    checked={isChecked(product.id)}
                    productData={product}
                    onCheckboxChange={handleCheckboxChange}
                />
            )}
            </tbody>
        </table>
    )
})
