import React, {useContext, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../hooks/ReduxHooks";
import "./OrderSidebar.sass"
import {ROUTE_URLS} from "../../../../constants/consts";
import {useNavigate, useParams} from "react-router-dom";
import {deleteOrderLines, fetchUpdateOrder, updateOrderLines} from "../../../../api/orders";
import {OrderLinesContext} from "../../../../hooks/UseOrderLinesSelectContext";
import {createBackendSaleOrder} from "../../../../api/productCart";
import {SaleOrderLineType} from "../../../catalog/types";
import {fetchOrderLines} from "../../../../store/slices/NewReturnSlice";
import Avatar from '../../../../images/avatar.png';
import {getSession} from "../../../../api/sessionManagement";
import {fetchSingleOrder} from "../../../../store/slices/OrderReducerSlice";


export const OrderSidebar = () => {
    const {orderData} = useAppSelector(state => state.order)
    const {activeFilter} = useAppSelector(state => state.newReturn)
    const {terms} = useAppSelector(state => state.finTerms)
    const navigate = useNavigate();
    const {selectedLines, lines, setIsLoading} = useContext(OrderLinesContext);
    const dispatch = useAppDispatch()
    const {orderId} = useParams()
    const { customerName, phone, email, city, postalCode, address, selectedCountry, selectedAddress, isDropshipping } = useAppSelector(state => state.orderShipping);

    const handleClickDownload = () => {
    }

    const updateReservationLines = async (linesForUpdate: SaleOrderLineType[]) => {
        if (!orderData || linesForUpdate.length === 0) return
        const updateResult = await updateOrderLines({
            "id_order": orderData.id,
            "line_data": linesForUpdate.map(el => {
                let lineData: { id: number, quantity: number, requested_qty?: number } = {
                    "id": el.id,
                    "quantity": (el.product_uom_qty - (el.toOrder || el.product_uom_qty))
                }

                return lineData
            })
        })
        return updateResult

    }

    const handleReduceReserve = async () => {
        if (!orderData || selectedLines.length === 0) return
        const linesToDelete = lines.filter(el => selectedLines.includes(el.id) && (!el.toOrder || el.toOrder === el.product_uom_qty)).map(el => el.id)
        let linesToUpdate = lines.filter(el => selectedLines.includes(el.id) && (!linesToDelete.includes(el.id)) && ((el.toOrder || el.product_uom_qty) > 0))
        if ((!linesToUpdate || linesToUpdate?.length === 0) && (!linesToDelete || linesToDelete?.length === 0)) return
        setIsLoading(true)
        const deleteResult = await deleteOrderLines({"ids": linesToDelete})
        if (deleteResult && deleteResult.error) {
            linesToUpdate.push(...lines.filter(el => linesToDelete.includes(el.id)))
        }
        await updateReservationLines(linesToUpdate)
        setIsLoading(false)
        navigate(`${ROUTE_URLS.RESERVES}/${orderData?.id}`)
    }

    const handleCreateOrder = async (isReserve = false) => {
        if (!orderData) return
        let linesForOrder: SaleOrderLineType[] = []
        if (selectedLines.length === 0) {
            linesForOrder = lines

        } else {
            linesForOrder = lines.filter(el => selectedLines.includes(el.id) && ((el.toOrder || el.product_uom_qty) > 0))
        }
        if (!linesForOrder) return
        const newSoData = {
            "is_reserve": isReserve,
            "is_active": false,
            "order_line": linesForOrder.map((el) => {
                return ({
                        "product_id": el.product_id.id,
                        "price_unit": el.price_unit,
                        "product_uom_qty": el.toOrder || el.product_uom_qty,
                    }
                )
            })
        }
        setIsLoading(true)
        let result = await createBackendSaleOrder(newSoData)
        const linesToDelete = linesForOrder.filter(el => (!el.toOrder || el.toOrder === el.product_uom_qty)).map(el => el.id)
        let linesToUpdate = linesForOrder.filter(el => !linesToDelete.includes(el.id))
        if (linesToDelete) {
            const deleteResult = await deleteOrderLines({"ids": linesToDelete})
            if (deleteResult && deleteResult.error) {
                linesToUpdate.push(...linesForOrder.filter(el => linesToDelete.includes(el.id)))
            }
        }
        if (linesToUpdate) {
            await updateReservationLines(linesToUpdate)
        }

        if (orderData.order_line.filter(el => !linesToDelete.includes(el.id)).length === 0) {
            await fetchUpdateOrder(orderData.id, {"state": "cancel"})
        }
        setIsLoading(false)
        if (result.error) return
        navigate(`${ROUTE_URLS.ORDERS}/${result?.data?.data?.id}`)
    }

    const handleConfirmOrder = () => {
        if (!orderData) return
        (async () => {
            setIsLoading(true)
            let updateData: {
                is_b2b_active: boolean;
                addressId?: number;
                customer_name?: string;
                phone?: string;
                email?: string;
                city?: string;
                postal_code?: string;
                address?: string;
                countryId?: number;
                isDropshipping?: boolean;
            } = {
                is_b2b_active: true,
                isDropshipping: isDropshipping,
            };

            if (isDropshipping) {
                updateData = {
                    ...updateData,
                    customer_name: customerName,
                    phone: phone,
                    email: email,
                    city: city,
                    postal_code: postalCode,
                    address: address,
                    countryId: selectedCountry,
                };
            } else {
                updateData = {
                    ...updateData,
                    addressId: selectedAddress,
                };
            }
            const result = await fetchUpdateOrder(orderData.id, updateData)
            if (!result?.error) {
                dispatch(fetchSingleOrder(Number(orderId)))
            }
            setIsLoading(false)
        })()
    }

    const handleCreateReturn = () => {
        if (!orderData) return
        const newFilter = {
            ...activeFilter,
            ["order_number"]: orderData.name,
        };
        dispatch(fetchOrderLines(newFilter))
        navigate(ROUTE_URLS.RETURNS)
    }

    return (orderData ?
            <>
                <div className="order_sidebar flex-grow-1 mb-4 mb-lg-0 pe-lg-4">
                    <div
                        className="position-relative d-flex align-items-center justify-content-md-center justify-content-lg-between flex-wrap gap-2">
                        <h2 className="mb-0 text-break"><span
                            className="currency_value">{orderData.amount_untaxed} {orderData.currency && orderData.currency.symbol}</span>
                        </h2>
                    </div>
                    <div className="d-flex flex-column gap-4 mt-3">
                        <div className="d-flex flex-column gap-2" id="sale_order_sidebar_button">
                            <div className="d-flex gap-2 flex-lg-column flex-xl-row flex-wrap">
                                <button
                                    className="btn btn-light flex-grow-1"
                                    title="Скачать"
                                    role="button"
                                    onClick={handleClickDownload}
                                >Скачать
                                </button>
                            </div>
                        </div>
                        <div>
                            <h6><small className="text-muted">Менеджер</small></h6>
                            <div className="d-flex flex-column gap-2">
                                <div className="avatar__wrapper d-flex gap-2">
                                    <img className="avatar portal_contact_img rounded" alt="..."
                                         src={Avatar}
                                         loading="lazy"/>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <div className="d-flex gap-2 flex-lg-column flex-xl-row flex-wrap">
                                {!!orderData.is_reserve ? (
                                    <div className="d-flex flex-column w-100 gap-1">
                                        <button
                                            className="btn btn-light order flex-grow-1"
                                            onClick={() => handleCreateOrder(false)}
                                            title="Создать заказ"
                                            role="button">
                                            Создать заказ
                                        </button>
                                        <button
                                            className="btn btn-light order flex-grow-1"
                                            onClick={() => handleReduceReserve()}
                                            title="Снять резерв"
                                            role="button">
                                            Снять резерв
                                        </button>
                                    </div>
                                ) : (orderData.is_b2b_active ? (
                                        <button
                                            className="btn btn-light order flex-grow-1"
                                            onClick={handleCreateReturn}
                                            title="Создать возврат"
                                            role="button">
                                            Создать возврат
                                        </button>
                                    ) : (
                                        <button
                                            className="btn btn-light order flex-grow-1"
                                            onClick={handleConfirmOrder}
                                            title="Подтвердить"
                                            role="button">
                                            Подтвердить
                                        </button>
                                    )

                                )}
                            </div>
                        </div>
                        <div className="d-flex gap-2 flex-lg-column flex-xl-row flex-wrap align-self-end">
                            Остаток по кредитному лимиту:
                            <h5 className="mb-0 text-break"><span
                                className="">{terms && terms.available_credit_limit.toFixed(2)} <i
                                className="bi bi-currency-euro"></i></span></h5>
                        </div>
                    </div>
                </div>
            </> : <></>
    )
}