import * as React from "react";
import {fetchReturnHistory} from "../../../api/returns";
import {useEffect, useState} from "react";
import {DATE_RANGE} from "../../../constants/consts";
import {SelectChangeEvent} from "@mui/material/Select";
import {TransactionsBarChart} from "../barChart/TransactionsBarChart";
import {Loader} from "../../loader/Loader";


export const ReturnHistory = () => {

    const [dataset, setDataset] = useState([]);
    const [groupBy, setGroupBy] = useState<DATE_RANGE>(DATE_RANGE.MONTH);
    const [isLoading, setIsLoading] = useState<boolean>(false)


    const getOrdersDataset = async () => {
        let result = await fetchReturnHistory(groupBy)
        setIsLoading(false)
        if (!result.error) {
            setDataset(result.data.data.dataset);
        }
    }

    useEffect(() => {
        setIsLoading(true)
        getOrdersDataset()
    }, [groupBy]);

    const handleChangeGroupBy = (event: SelectChangeEvent) => {
        setGroupBy(event.target.value as DATE_RANGE);
    }

    if (!dataset) return <></>

    return (
        <TransactionsBarChart
            dataset={dataset}
            groupBy={groupBy}
            isLoading={isLoading}
            handleChangeGroupBy={handleChangeGroupBy}
            label="История возвратов"
        />)
}