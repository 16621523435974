import axios from "axios";
import { resolve } from "./resolve.js";
import {API_BACKEND_URL, RETURN_STATE} from "../constants/consts";
import {getSession} from "./sessionManagement";

export type CreateReturnDataType = {
    responsible_user_id?: number | undefined;
    lines: {
        id: number | undefined;
        quantity: number;
        state: string | undefined;
        comment: string | undefined;
    }[];
}

export type UpdateReturnDataType = {
    id: number;
    return_state: RETURN_STATE;
}

export async function fetchReturnsRequests(pageNum: number, isActive: boolean = false) {
    const sessionId = getSession()
    const additionalParams = `?is_active=${isActive}`
    const URL = `${API_BACKEND_URL}/sales/returns/page/${pageNum}${additionalParams}`
    return await resolve(axios.get(URL,
            {
                headers: {
                    "Content-Type": "application/json",
                    "session_id": sessionId,
                }
            }
        ).then(res => res)
    );
}

export async function fetchSingleReturnRequests(returnId: Number) {
    const sessionId = getSession()
    const URL = `${API_BACKEND_URL}/sales/returns/${returnId}`
    return await resolve(axios.get(URL,
            {
                headers: {
                    "Content-Type": "application/json",
                    "session_id": sessionId,
                }
            }
        ).then(res => res)
    );
}

export async function fetchReturnHistory(groupby: string) {
    const sessionId = getSession()
    const URL = `${API_BACKEND_URL}/sales/get_return_history`
    return await resolve(axios.post(URL,
        {groupby: groupby || "day"},
        {
            headers: {
                "Content-Type": "application/json",
                "session_id": sessionId,
            }
        }
    ).then(res => res)
    );
}

export async function createBackendReturn(data: CreateReturnDataType) {
    const URL = `${API_BACKEND_URL}/sales/create_return`
    return await resolve(axios.post(URL,
        data, {
            headers: {
                'Content-Type': 'application/json',
                'session_id': getSession(),
            }
        }
    ).then(res => res));
}

export async function updateBackendReturn(data: UpdateReturnDataType){
    const URL = `${API_BACKEND_URL}/sales/returns/update_return`
    return await resolve(axios.patch(URL,
        data,{
            headers: {
                'Content-Type': 'application/json',
                'session_id': getSession(),
            }
        }
    ).then(res => res));
}

