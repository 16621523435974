import {resolve} from "./resolve";
import axios from "axios";
import {API_AUTH_URL, USER_ROLES} from "../constants/consts";

export  function getSession() {
    return localStorage.getItem('sessionId');
}

export  function setSession(sessionId){
    return localStorage.setItem('sessionId', sessionId);
}

export  function clearSession() {
    localStorage.removeItem('sessionId');
}

export function setSessionUserRole(role){
    return localStorage.setItem('userRole', role);
}

export function getSessionUserRole(){
    return localStorage.getItem('userRole');
}

export function setUserEmail(email){
    return localStorage.setItem('userEmail', email);
}

export function getUserEmail(){
    return localStorage.getItem('userEmail');
}

export function clearSessionUserRole(){
    return localStorage.removeItem('userRole');
}

export function clearLocalStorage() {
    return localStorage.clear();
}

export function setSessionUserCompany(companyName){
    return localStorage.setItem("companyName",companyName)
}

export function getSessionUserCompany(){
    return localStorage.getItem('companyName');
}


export async function changeUserRoleForQuestionaryBlock(){
    return await resolve(axios.patch(`${API_AUTH_URL}/users/role`,
        {
            "email":getUserEmail(),
            "role": USER_ROLES.WAITING,
        }
    ).then(res => res));
}

export async function createNewRole(){
    return await resolve(axios.post(`${API_AUTH_URL}/roles`,
        {
            "roles": [
                USER_ROLES.WAITING
            ]
        }
    ).then(res => res));
}


export async function checkUserRole(){
    return await resolve(axios.post(`${API_AUTH_URL}/users/check`,
        {
            "session_id": getSession()
        }
    ).then(res => res));
}

export function setIsTwoFactorAuthorized(isTwoFactorAuthorized) {
    return localStorage.setItem('isTwoFactorAuthorized', isTwoFactorAuthorized);
}
export function getIsTwoFactorAuthorized() {
    return JSON.parse(localStorage.getItem('isTwoFactorAuthorized'));
}

export function getTwoFactorEnabled() {
    return JSON.parse(localStorage.getItem('isEnabledTwoFactor'));
}

export function setTwoFactorEnabled(isEnabledTwoFactor) {
    return localStorage.setItem('isEnabledTwoFactor', isEnabledTwoFactor);
}

export function setUserId(isTwoFactorAuthorized) {
    return localStorage.setItem('userId', isTwoFactorAuthorized);
}
export function getUserId() {
    return JSON.parse(localStorage.getItem('userId'));
}
