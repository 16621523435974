import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";


import "./ChildSubSidebar.sass"
import {ROUTE_URLS, SUBMENU_TYPES} from "../../constants/consts";
import {Link} from "react-router-dom";


interface SubSidebarProps {
    className: string;
    closeAllCategory: any
    isChildSubSidebarClose: boolean;
    setIsChildSubSidebarClose: any;
    submenuType: SUBMENU_TYPES | undefined
}


export const OrdersSubmenu = (props: SubSidebarProps) => {

    const ordersSubItems = [
        {
            name: "Резервации",
            path: ROUTE_URLS.RESERVES
        },
        {
            name: "Заказы",
            path: ROUTE_URLS.ORDERS
        },
        {
            name: "Возврат продукта",
            path: ROUTE_URLS.RETURNS
        },
        {
            name: "Проблемы с доставкой",
            path: ""
        },
    ]


    return (
        <>
            <div className={`mainCategory ${props.className}`}>
                <div className="mainCategoryWrapper">
                    <div className="mainCategoryButtonBack" onClick={props.closeAllCategory}>
                        <ChevronLeftIcon/>
                        <span>{props.submenuType}</span>
                    </div>
                    <ul className="ordersCategoryList">
                        {ordersSubItems.map((item,key) =>
                                <Link key={key} onClick={props.closeAllCategory} to={item.path}>
                                    <li key={key}>{item.name}</li>
                                </Link>
                        )}
                    </ul>
                </div>
            </div>
        </>

    )
}
