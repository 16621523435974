import React, {useEffect} from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {ChildSubSidebar} from "./ChildSubSidebar";
import {useState} from "react";
import {getParentCategories} from "../../api/categories";
import {getChildCategories} from "../../api/categories";


import "./ChildSubSidebar.sass"
import {SUBMENU_TYPES} from "../../constants/consts";


interface SubSidebarProps {
    className: string;
    closeAllCategory: any
    isChildSubSidebarClose: boolean;
    setIsChildSubSidebarClose: any;
    submenuType: SUBMENU_TYPES | undefined
}


export const CategoriesSubmenu = (props: SubSidebarProps) => {

    const [active, setActive] = useState<number>();
    const [activeCategoryName, setActiveCategoryName] = useState<string>("");
    const [childSubSidebarClassName, setChildSubSidebarClassName] = useState<string>("");
    const [categoriesData, setCategoriesData] = useState<any>([]);
    const [childCategoriesData, setChildCategoriesData] = useState<any[]>([]);


    const openChildSubCategory = async (activeCategoryId: number) => {
        let currentActiveCategoryName = categoriesData.filter((category:any) => {return category.id === activeCategoryId})[0]?.name
        setActive(activeCategoryId);
        setActiveCategoryName(currentActiveCategoryName)
        await parseChildCategory(activeCategoryId);
        props.setIsChildSubSidebarClose(false)
        setChildSubSidebarClassName("slideChildSubCategory")
    };

    const closeChildSubCategory = async () => {
        props.setIsChildSubSidebarClose(true)
        setChildSubSidebarClassName("")
    }

    async function parseChildCategory(activeCategoryId: number) {
        let result = await getChildCategories(activeCategoryId);
        if (!result.error) {
            setChildCategoriesData(result.data.data);
        }
    }

    async function parseMainCategories() {
        let result = await getParentCategories();
        if (!result.error && !categoriesData.length && result.data.data) {
            setCategoriesData(result.data.data);
        }
    }

    useEffect(() => {
        (async () => {
            if (!categoriesData.length) {
                await parseMainCategories();
            }
        })();
    }, []);


    return (
        <>
            <div className={`mainCategory ${props.className}`}>
                <div className="mainCategoryWrapper">
                    <div className="mainCategoryButtonBack" onClick={props.closeAllCategory}>
                        <ChevronLeftIcon/>
                        <span>{props.submenuType}</span>
                    </div>
                    <ul className="mainCategoryList">
                        {categoriesData.map((category:any) =>
                            <li className={`${active === category?.id ? "li-active" : ""}`} id={category?.id}
                                key={category?.id} onClick={() => openChildSubCategory(category?.id)}>
                                {category?.name}
                            </li>
                        )}
                    </ul>
                </div>
            </div>
            <ChildSubSidebar
                closeChildSubCategory = {closeChildSubCategory}
                isChildSubSidebarClose={props.isChildSubSidebarClose}
                closeAllCategory={props.closeAllCategory}
                categoryData={childCategoriesData}
                activeCategoryName = {activeCategoryName}
                className={childSubSidebarClassName}
            />
        </>

    )
}
