import { useState, useEffect } from 'react'
import {MESSAGE_VARIANT} from "../../constants/consts";
import "./Message.sass"
import {Alert, Grow} from "@mui/material";

interface MessageProps {
    variant: MESSAGE_VARIANT;
    children: any
    timeout?: number,
    hideMessage: () => void
}

const Message = (props: MessageProps) => {
    const { variant, children, timeout, hideMessage } = props;
    const [massageTimeId, setMassageTimeId] = useState<NodeJS.Timeout | undefined>(undefined)

    useEffect(() => {
        const timeId = setTimeout(() => {
          hideMessage()
        }, timeout || 4000)

        setMassageTimeId(timeId)
        return () => {
            hideMessage()
            clearTimeout(timeId)
        }}, []);

      if (!children) {
        return null;
      }

  const handleClose = () => {
          hideMessage();
          clearTimeout(massageTimeId)
  }

  return (
      <div className={`message_alert`}>
          <Grow
              in={children}
              style={{ transformOrigin: '0 0 0' }}
              {...(children ? { timeout: 500 } : {})}
          >
              <Alert severity={variant} onClose={handleClose}>
                  {children}
              </Alert>
          </Grow>
      </div>
  )
}


export default Message;