import axios from "axios";
import { resolve } from "./resolve.js";
import {API_BACKEND_URL} from "../constants/consts";
import {getSession} from "./sessionManagement";


export async function fetchProductsRequests(pageNum, categoryId, searchValue, activeFilters) {
    const sessionId = getSession()
    let requestUrl = `${API_BACKEND_URL}/products/page/${pageNum}/${categoryId}`
    if (activeFilters && searchValue) {
        requestUrl += `?filter=${JSON.stringify([{fields: activeFilters.join(","), term: searchValue}])}`
    }

    return await resolve(axios.get(requestUrl,
        {
            headers: {
                "Content-Type": "application/json",
                "session_id": sessionId,
            }
        }
    ).then(res => res)
    );
}
