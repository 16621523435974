import {TableRow} from "./tableRow/TableRow"
import {TableHeader} from "./tableHeader/TableHeader"
import {BasePagination} from "../../pagination/Pagination";
import {useAppSelector} from "../../../hooks/ReduxHooks";
import "./CatalogTable.sass"
import {useParams} from "react-router-dom";



export const CatalogTable = () => {
    const {
        productsCount,
        maxProductCount,
        productInfo,
        pageNumber,
        pagesCount
    } = useAppSelector(state => state.catalogProducts)

    const {categoryId} = useParams();

    return (
        <div className="productTableWrapper">
            <table className="productTable">
                <thead className="productTable__header">
                <TableHeader/>
                </thead>
                <tbody className="productTable__body">
                {productInfo && productInfo.map((child) =>
                    <TableRow key={child.id} categoryID={categoryId} productData={child}/>
                )}
                </tbody>
            </table>
            <div>
                {pagesCount > 1 && <BasePagination count={pagesCount}/>}
            </div>
        </div>
    )
}


