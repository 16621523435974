import axios from 'axios';
import {resolve} from './resolve.js';
import {API_BACKEND_URL} from "../constants/consts";
import {getSession} from "./sessionManagement";


export async function getParentCategories() {
    const sessionId = getSession()
    return await resolve(axios.get(`${API_BACKEND_URL}/categories/parent`,
        {
            headers: {
                "Content-Type": "application/json",
                "session_id": sessionId,
            }
        }
    ).then(res => res));
}

export async function getChildCategories(activeCategoryId) {
    const sessionId = getSession()
    return await resolve(axios.get(`${API_BACKEND_URL}/categories/children/${activeCategoryId}`,
        {
            headers: {
                "Content-Type": "application/json",
                "session_id": sessionId,
            }
        }
    ).then(res => res));
}


export async function fetchCategory(categoryId){
    return await resolve(axios.get(`${API_BACKEND_URL}/categories/category/${categoryId}`,
        {
            headers: {
                "Content-Type": "application/json",
                "session_id": getSession(),
            }
        }
    ).then(res => res));
}