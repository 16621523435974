// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.catalog_product__link {
  text-decoration: none;
  color: #000000;
}`, "",{"version":3,"sources":["webpack://./src/pages/productCatalogPage/productCatalogPage.sass"],"names":[],"mappings":"AACE;EACE,qBAAA;EACA,cAAA;AAAJ","sourcesContent":[".catalog_product\n  &__link\n    text-decoration: none\n    color: #000000"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
