import * as React from "react";
import {NavLink, useLocation} from "react-router-dom";
import {breadcrumbNameMap} from "../../constants/consts";
import {breadcrumbNameMapExclude} from "../../constants/consts";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { useDispatch } from 'react-redux';

import {updateBreadcrumb} from "../../store/slices/BreadcrumbSlice";
import {useEffect} from "react";
import {useAppSelector} from "../../hooks/ReduxHooks";

interface BaseBreadcrumbProps {
  data?: BreadcrumbsDataProps
}

export interface BreadcrumbsDataProps {
  [url: string]: string;
}


export const BaseBreadCrumbs = (props: BaseBreadcrumbProps) => {

  const separator=" / "
  const breadcrumbs = useBreadcrumbs(breadcrumbNameMap, {excludePaths: breadcrumbNameMapExclude})
  const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1]
  const crumbs = useAppSelector(state => state.breadcrumb.crumbs)
  const dispatch = useDispatch();
  const location = useLocation()

  const handleAddKeyValuePair = (newKey:any, newValue:any) => {
    dispatch(updateBreadcrumb({ key: newKey, value: newValue }));
  };

  const preprocessCrumbs = () =>{
    for (const key of breadcrumbs) {
      let path = key.match.pathname
      handleAddKeyValuePair(path, props.data?.hasOwnProperty(path) ? props.data[path] : key.match.route?.breadcrumb)
    }
  }


  useEffect(() => {
    preprocessCrumbs()
  }, [location]);


  return (
      <div>
              {
                  breadcrumbs.map(({match, breadcrumb}) => (

                      <NavLink end={lastBreadcrumb.match !== match} key={match.pathname} to={match.pathname} style={({isActive, isPending, isTransitioning}) => ({
                          color: isActive ? '#808084' : '#6ed1f3',
                          textDecoration: 'none',
                          cursor: isActive ? "default" : "pointer",

                      })}>
                        {crumbs[match.pathname]}
                        {lastBreadcrumb.match !== match ? <span style={{color:"#6ed1f3", cursor: "default", margin: "0 5px"}}><b>{separator}</b> </span>: <span> </span>}
                      </NavLink>

                  ))
              }
      </div>
  )


}
