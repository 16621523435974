import {FilterButton} from "./styles";

interface CatalogFilterPropsValues {
    ToggleCatalogFilter?: any;
}

export const CatalogFilter = (props: CatalogFilterPropsValues) => {

    const {ToggleCatalogFilter} = props

    return (
        <FilterButton className="btn text-colored">
            <i className="bi bi-funnel"></i>
        </FilterButton>
    )
}

