import {FavButton} from "../../favButton/FavButton"
import {DetailedDescription} from "./detailedDescription/DetailedDescription"
import {Link} from "react-router-dom";
import "../CatalogTable.sass"
import "./TableRow.sass"
import {setPreviewProduct} from "../../../../store/slices/PreviewProductSlice";
import {ProductCardControl} from "../../../productCardControl/ProductCardControl";
import * as React from "react";
import {useRef} from "react";
import {useAppDispatch, useAppSelector} from "../../../../hooks/ReduxHooks";
import {TableRowImage} from "./TableRowImage";
import {ToOrder} from "../../toOrder/ToOrder";


interface TableRowPropsValues {
    value?: number;
    productData: any;
    categoryID: any;
}

interface ProductCardControlRef {
    setIsInCard: (newState: any) => void;
    setValue: (newState: any) => void;
}

export const TableRow = (props: TableRowPropsValues) => {
    const productCardControlRef = useRef<ProductCardControlRef>(null);
    const {activeDisplayFields} = useAppSelector(state => state.page);

    const dispatch = useAppDispatch()

    const handleOnClickImage = () => {
        if (!props.productData) return;
        dispatch(setPreviewProduct(props.productData.id))
    }

    const productUrl = `/products${props.categoryID ? "/" + props.categoryID : ""}/product-card/${props.productData.id}`


    return (
        <tr key={`product_${props.productData.id}`} className="catalog-table-row">
            {activeDisplayFields.includes("image") && (
                <td key={`product_img_${props.productData.id}`} scope="row" className="catalog-table-row-image">
                    <TableRowImage
                        src={props.productData.image_1920}
                        handleOnClickImage={handleOnClickImage}
                    />
                </td>
            )}
            {activeDisplayFields.includes("default_code") && (
                <td key={`product_code_${props.productData.id}`} className="catalog-table-row-code">
                    <Link className="catalog_product__link" to={productUrl}>
                        <p className="text-start fw-bold">{props.productData.default_code}</p>
                    </Link>
                </td>
            )}
            {activeDisplayFields.includes("product_code") && (
                <td key={`product_name_${props.productData.id}`}>
                    <Link className="catalog_product__link" to={productUrl}>
                        <p className="text-start fw-bold">{props.productData.name}</p>
                    </Link></td>
            )}
            <td><FavButton isColored/></td>
            <td key={`product_full_name_${props.productData.id}`} className="eclipse-content">
                <Link className="catalog_product__link" to={productUrl}>
                    <p className="text-start fw-bold">{props.productData.name}</p>
                </Link>
                <DetailedDescription/>
                <div className="d-flex flex-row"><span
                    className="badge text-bg-warning">Attach Office and get discount</span></div>

            </td>
            {activeDisplayFields.includes("brand") && (
                <td key={`product_brand_${props.productData.id}`}><p className="fw-bold text-center">{props.productData.brand}</p></td>
            )}
            <td>
                <p className="fw-bold text-success text-center">
                {props.productData.is_display_stock_balance_on_portal && (
                    props.productData.stock_quantity_b2b <= props.productData.balance_no_more ? (
                        <span> {props.productData.stock_quantity_b2b}</span>
                    ) : (
                        <span> более {props.productData.balance_no_more}</span>
                    )
                )}
            </p>
                <ToOrder productData={props.productData}/>
            </td>
            <td key={`product_price_${props.productData.id}`}><p
                className="fw-bold text-center">{props.productData.product_price} {props.productData.currency.symbol}
            </p>
            </td>
            <td key={`product_cart_${props.productData.id}`}>
                <ProductCardControl
                    ref={productCardControlRef}
                    isListView isCanInput
                    isCanLock
                    productData={{
                        id: props.productData.id,
                        is_for_order: props.productData.is_for_order,
                        balance_no_more: props.productData.balance_no_more,
                        stock_quantity_b2b: props.productData.stock_quantity_b2b,
                        is_display_stock_balance_on_portal: props.productData.is_display_stock_balance_on_portal,
                        price: props.productData.product_price,
                        currencyId: props.productData.currency.id,
                    }}/>
            </td>
        </tr>
    )
}