import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";


import "./ChildSubSidebar.sass"
import {ROUTE_URLS, SUBMENU_TYPES} from "../../constants/consts";
import {Link} from "react-router-dom";


interface SubSidebarProps {
    className: string;
    closeAllCategory: any
    isChildSubSidebarClose: boolean;
    setIsChildSubSidebarClose: any;
    submenuType: SUBMENU_TYPES | undefined
}


export const GuaranteeSubmenu = (props: SubSidebarProps) => {

    const guaranteeSubItems = [
        {
            name: "Гарантийные условия",
            path: ROUTE_URLS.WARRANTY_TERMS
        },
        {
            name: "Заявки",
            path: ROUTE_URLS.WARRANTY_REQUEST
        },
        {
            name: "Гарантийные сроки",
            path: ROUTE_URLS.WARRANTY_PERIODS
        },
        {
            name: "Инструкция по Гарантийному случаю",
            path: ROUTE_URLS.WARRANTY_CASE
        },
    ]


    return (
        <>
            <div className={`mainCategory ${props.className}`}>
                <div className="mainCategoryWrapper">
                    <div className="mainCategoryButtonBack" onClick={props.closeAllCategory}>
                        <ChevronLeftIcon/>
                        <span>{props.submenuType}</span>
                    </div>
                    <ul className="ordersCategoryList">
                        {guaranteeSubItems.map((item,key) =>
                            <Link key={key} onClick={props.closeAllCategory} to={item.path}>
                                <li key={key}>{item.name}</li>
                            </Link>
                        )}
                    </ul>
                </div>
            </div>
        </>

    )
}
