import {FC} from "react";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import {StyledDatePicker} from "./styles";

interface BaseDatePickerProps {
    label: string;
    sx?: any;
    slotProps?: any
}


export const BaseDatePicker: FC<BaseDatePickerProps> = ({label, sx, slotProps}) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StyledDatePicker slotProps={slotProps} sx={sx} label={label}/>
        </LocalizationProvider>
    )
}

