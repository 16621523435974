import {FC, memo, useEffect, useState} from "react";
import {
    SearchPanelBaseContainer,
    SearchPanelCol,
    SearchPanelFormButton,
    SearchPanelFormInput,
    SearchPanelInputGroup,

} from "./styles";
import * as React from "react";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import {setCatalogSearchValue} from "../../store/slices/PagePaginagionSlice";
import {useLocation} from "react-router-dom";
import {EMPTY_STRING, SEARCH_PARAMS} from "../../constants/consts";


export const SearchPanelBase = memo(() => {

    const location = useLocation();

    const [searchValue, setSearchValue] = useState<any>("");
    const [placeholder, setPlaceholder] = useState("")
    const {activeFilters} = useAppSelector(state => state.page)

    useEffect(() => {
        setSearchValue(EMPTY_STRING)
        dispatch(setCatalogSearchValue(EMPTY_STRING))
    }, [location]);

    useEffect(() => {
        setPlaceholder(activeFilters.map(item => SEARCH_PARAMS[item]).join(' / '))
    }, [activeFilters]);


    const handleOnChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value)
    }

    const dispatch = useAppDispatch()

    const handleOnClickSearch = (event: React.FormEvent) => {
        dispatch(setCatalogSearchValue(searchValue))
    }

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            dispatch(setCatalogSearchValue(searchValue))
        }
    }

    return (
        <SearchPanelBaseContainer>
            <SearchPanelCol>
                <SearchPanelInputGroup className="input-group">
                    <SearchPanelFormButton type="submit" className="btn favIcon" onClick={handleOnClickSearch}>
                        <i className="bi bi-search"></i>
                    </SearchPanelFormButton>
                    <SearchPanelFormInput onKeyDown={handleKeyPress} value={searchValue} onChange={handleOnChangeSearch} type="text" className="form-control" placeholder={placeholder}/>
                </SearchPanelInputGroup>
            </SearchPanelCol>
        </SearchPanelBaseContainer>
    )
})