import React, {useEffect, useState} from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {ROUTE_URLS, SUBMENU_TYPES} from "../../constants/consts";
import {Link, To} from "react-router-dom";
import "./ChildSubSidebar.sass"


interface SubSidebarProps {
    className: string;
    closeAllCategory: any
    isChildSubSidebarClose: boolean;
    setIsChildSubSidebarClose: any;
    submenuType: SUBMENU_TYPES | undefined
}


type activeCategoryType = {
    name?: string;
    id: any;
    submenu?: { id: number; name: string; path: string; }[];
    path?: undefined;
}

export const QuestionaryMenu = (props: SubSidebarProps) => {

    const [activeItem, setActiveItem] = useState<any>(undefined);

    const openChildSubCategory = async (activeCategory: activeCategoryType) => {
        props.setIsChildSubSidebarClose(false)
        if (activeCategory) setActiveItem(activeCategory)
    };

    const closeSubCategory = () => {
        props.setIsChildSubSidebarClose(true)
    }

    const ordersSubItems = [
        {
            name: "Данные партнера",
            id: 1,
            path: ROUTE_URLS.QUESTIONARY
        },
        {
            name: "Данные MK Trade",
            id: 2,
            submenu: [
                {
                    id: 21,
                    name: "Контактная Информация",
                    path: ROUTE_URLS.CONTACT_INFORMATION,
                },
                {
                    id: 22,
                    name: "Пользовательское соглашение",
                    path: ROUTE_URLS.TERM_SERVICE,
                },
            ]
        }
    ]


    return (
        <>
            <div className={`mainCategory ${props.className}`}>
                <div className="mainCategoryWrapper">
                    <div className="mainCategoryButtonBack" onClick={props.closeAllCategory}>
                        <ChevronLeftIcon/>
                        <span>{props.submenuType}</span>
                    </div>
                    <ul className="ordersCategoryList">
                        {ordersSubItems.map((item,key) => {
                            if ("path" in item) {
                                return (
                                    <Link key={key} onClick={props.closeAllCategory} to={`${item.path}`}>
                                        <li key={key}>{item.name}</li>
                                    </Link>
                                )
                            } else {
                                return (
                                    <li className={`${activeItem?.id === item?.id ? "li-active" : ""}`}
                                        id={`${item?.id}`}
                                        key={key} onClick={() => openChildSubCategory(item)}>
                                        {item?.name}
                                    </li>
                                )
                            }
                            }
                        )}
                    </ul>
                </div>
            </div>
            {activeItem && (
                <QuestionarySubMenu
                    activeItem={activeItem}
                    closeAllCategory={props.closeAllCategory}
                    closeSubCategory={closeSubCategory}
                    isChildSubSidebarClose={props.isChildSubSidebarClose}
                    className={"slideChildSubCategory"}
                />
            )}
        </>

    )
}

interface ChildSubSidebarProps {
    className: string;
    closeAllCategory: () => void;
    closeSubCategory: () => void;
    isChildSubSidebarClose: boolean;
    activeItem: any;
}

export const QuestionarySubMenu = (props: ChildSubSidebarProps) => {
    return (
        <div className={`childCategory sm ${!props.isChildSubSidebarClose ? props.className : ""}`}>
            <div className="childCategoryWrapper">
                <div className="childCategoryListWrapperScroll">
                    <div className="mainCategoryButtonBack subMenu" onClick={props.closeSubCategory}>
                        <ChevronLeftIcon/>
                        <span>{props?.activeItem?.name}</span>
                    </div>
                    <div className="financialCategoryListWrapper">
                        {props?.activeItem?.submenu && (
                            <ul className={"ordersCategoryList"}>{props.activeItem.submenu.map((category: { path: To; name: string},key:any) =>
                                <li key={key}>
                                    <Link  onClick={props.closeAllCategory} to={category.path}>{category?.name}</Link>
                                </li>
                            )}
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
