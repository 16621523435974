import axios, {AxiosResponse} from 'axios';
import {resolve} from './resolve.js';
import {API_AUTH_URL} from "../constants/consts";

export async function userLogin(email:any, password:any, region:any, companyName:any, vat:any, phone:any, registration:any, zip:any, city:any, address:any, owner:any, boards:any):Promise<AxiosResponse["data"]> {
    return await resolve(axios.post(`${API_AUTH_URL}/users/registration`,
        {
            email: email,
            phone: phone,
            company: {
                name: companyName,
                vat: vat,
                boards: boards.map((item: any) => ({ name: item })),
                registration_number:registration,
                address: {
                    street:address,
                    postal_code:zip,
                    city:city,
                    region: region,
                },
                beneficial_owner: owner.map((item: any) => ({ name: item })),
            },
        }
    ).then(res => res));
}
