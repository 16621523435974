import "./productCatalogPage.sass"
import {CatalogFilterWrapper, CatalogItemsCount, CatalogPage} from "./styles";
import {SearchPanelBase} from "../../components/searchPanel/SearchPanelBase";
import {CatalogContent} from "../../components/catalog/CatalogContent"
import {CatalogFilter} from "../../components/catalog/catalogFilter/CatalogFilter"
import {FormGroup, FormControlLabel, Checkbox} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../hooks/ReduxHooks";
import React, {useEffect, useState} from "react";
import {
    extendCatalogProducts,
    fetchCatalogProducts
} from "../../store/slices/CatalogProductsReducerSlice";
import {setDisplayType, updatePage} from "../../store/slices/PagePaginagionSlice";
import {useLocation, useParams} from "react-router-dom";
import {DISPLAY_TYPE} from "../../constants/consts";
import {Loader} from "../../components/loader/Loader";
import {updateBreadcrumb} from "../../store/slices/BreadcrumbSlice";
import {ProductB2BCategory} from "../../components/catalog/types";
import {fetchCategory} from "../../api/categories";

export const ProductCatalogPage = () => {

    const {categoryId} = useParams()
    const {page, displayType, searchValue} = useAppSelector((state: any) => state.page)
    const {activeFilters} = useAppSelector(state => state.page)
    const dispatch = useAppDispatch()
    const location = useLocation()
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const {productInfo, maxProductCount, isProductLoading, productList } = useAppSelector(state => state.catalogProducts)
    const {isCartLoading} = useAppSelector(state => state.activeCart)


    useEffect(() => {
        dispatch(setDisplayType(DISPLAY_TYPE.TABLE))
    }, [categoryId]);

    useEffect(() => {
        if (!isProductLoading && !isCartLoading)
            setIsLoading(false)
    }, [isProductLoading, isCartLoading]);

    useEffect(() => {
        dispatch(updatePage(1))
    }, [categoryId, displayType]);

    useEffect(() => {
        if (page === 0) return
        const requestData = {
            pageNum: page,
            categoryId: Number(categoryId || 1),
            searchValue: searchValue,
            activeFilters: activeFilters,
        };
        displayType === "grid" && page !== 1
            ? dispatch(extendCatalogProducts(requestData))
            : dispatch(fetchCatalogProducts(requestData));
    }, [categoryId, page, searchValue]);


    const getCategory = async (data: string | any[]) => {
        if (!categoryId) return
        let result = await fetchCategory(categoryId)
        if (result.error) return
        const category = result.data.data as ProductB2BCategory
        if (!category) return undefined
        return category
    }

    useEffect(() => {
        (async () => {
            if (productList && categoryId && productList.length > 0) {
                const relatedCategory = await getCategory(categoryId)
                if (!relatedCategory) return
                dispatch(updateBreadcrumb({key: location.pathname, value: relatedCategory.name}))
            }
        })();
    }, [categoryId, productList]);


    return (
        <>
            {(isLoading) ? (
                <Loader/>
            ) : (
                <>
                    <CatalogFilterWrapper>
                        <CatalogFilter/>
                        <div className={"w-100"}>
                            <SearchPanelBase/>
                        </div>
                        <FormGroup row className="flex-nowrap">
                            <FormControlLabel control={<Checkbox/>} label="Только в наличии" color="default"
                                              className={"text-nowrap"}/>
                        </FormGroup>
                        {maxProductCount ? (
                            <CatalogItemsCount>{maxProductCount}</CatalogItemsCount>
                        ) : (
                            <></>
                        )
                        }
                    </CatalogFilterWrapper>
                    <CatalogContent/>
                </>
            )
            }
        </>
    )
}
