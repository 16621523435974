import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DownloadIcon from '@mui/icons-material/Download';
import {useState} from "react";


import "./ChildSubSidebar.sass"
import {ENDPOINT, ROUTE_URLS, SUBMENU_TYPES} from "../../constants/consts";
import {Link, To} from "react-router-dom";
import {getUserPriceList} from "../../api/priceList";


interface SubSidebarProps {
    className: string;
    closeAllCategory: any
    isChildSubSidebarClose: boolean;
    setIsChildSubSidebarClose: any;
    submenuType: SUBMENU_TYPES | undefined
}

type activeCategoryType = {
    name?: string;
    id: any;
    submenu?: { id: number; name: string; path: string; }[];
    path?: undefined;
}


export const FinancialMenu = (props: SubSidebarProps) => {

    const [activeItem, setActiveItem] = useState<any>(undefined);


    const openChildSubCategory = async (activeCategory: activeCategoryType) => {
        props.setIsChildSubSidebarClose(false)
        if (activeCategory) setActiveItem(activeCategory)
    };

    const closeSubCategory = () => {
        props.setIsChildSubSidebarClose(true)
    }

    const downloadUserPriceList = async () => {
        let result = await getUserPriceList();
        if (!result.error) {
            window.location.assign(`${ENDPOINT}/pricelist/download-pricelist/${result.data.data.attach_id}`);
        }

    }

    const ordersSubItems = [
        {
            name: "Прайс лист",
            id: 0,
            method: "downloadUserPriceList"

        },
        {
            name: "Финансовые условия",
            id: 1,
            path: ROUTE_URLS.FIN_TERMS
        },
        {
            name: "Финансовые документы",
            id: 2,
            submenu: [
                {
                    id: 21,
                    name: "Счета",
                    path: "",
                },
                {
                    id: 22,
                    name: "Баланс",
                    path: "",
                },
            ]
        },
        {
            name: "Запрос об изменении кредитной линии",
            id: 3,
            submenu: [
                {
                    id: 31,
                    name: "Новый запрос",
                    path: ROUTE_URLS.NEW_CREDIT_LIMIT,
                },
                {
                    id: 32,
                    name: "История запросов",
                    path: ROUTE_URLS.CREDIT_LIMIT_HISTORY,
                },
            ]
        },
    ]


    return (
        <>
            <div className={`mainCategory ${props.className}`}>
                <div className="mainCategoryWrapper">
                    <div className="mainCategoryButtonBack" onClick={props.closeAllCategory}>
                        <ChevronLeftIcon/>
                        <span>{props.submenuType}</span>
                    </div>
                    <ul className="ordersCategoryList">
                        {ordersSubItems.map((item,key) => {
                                if ("path" in item) {
                                    return (
                                        <Link key={key} onClick={props.closeAllCategory} to={`${item.path}`}>
                                            <li key={key}>{item.name}</li>
                                        </Link>
                                    )
                                }
                                else if ("method" in item && item.method === "downloadUserPriceList") {
                                    return (
                                        <li className={`${activeItem?.id === item?.id ? "li-active" : ""}`}
                                            id={`${item?.id}`}
                                            key={key} onClick={() => downloadUserPriceList()}>
                                            {item?.name}
                                            <DownloadIcon/>
                                        </li>
                                    )

                                } else {
                                    return (
                                        <li className={`${activeItem?.id === item?.id ? "li-active" : ""}`}
                                            id={`${item?.id}`}
                                            key={key} onClick={() => openChildSubCategory(item)}>
                                            {item?.name}
                                        </li>
                                    )
                                }
                            }
                        )}
                    </ul>
                </div>
            </div>
            {activeItem && (
                <FinancialSubmenu
                    activeItem={activeItem}
                    closeAllCategory={props.closeAllCategory}
                    closeSubCategory={closeSubCategory}
                    isChildSubSidebarClose={props.isChildSubSidebarClose}
                    className={"slideChildSubCategory"}
                />
            )}
        </>


    )
}

interface ChildSubSidebarProps {
    className: string;
    closeAllCategory: () => void;
    closeSubCategory: () => void;
    isChildSubSidebarClose: boolean;
    activeItem: any;
}

export const FinancialSubmenu = (props: ChildSubSidebarProps) => {
    return (
        <div className={`childCategory sm ${!props.isChildSubSidebarClose ? props.className : ""}`}>
            <div className="childCategoryWrapper">
                <div className="childCategoryListWrapperScroll">
                    <div className="mainCategoryButtonBack subMenu" onClick={props.closeSubCategory}>
                        <ChevronLeftIcon/>
                        <span>{props?.activeItem?.name}</span>
                    </div>
                    <div className="financialCategoryListWrapper">
                        {props?.activeItem?.submenu && (
                            <ul className={"ordersCategoryList"}>{props.activeItem.submenu.map((category: { path: To; name: string},key:any) =>
                                <li key={key}>
                                    <Link key={key} onClick={props.closeAllCategory} to={category.path}>{category?.name}</Link>
                                </li>
                            )}
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}