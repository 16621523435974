import React, { useEffect, useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router-dom";
import { getChildCategories } from "../../api/categories";
import "./SubSidebar.sass";

interface Category {
    id: number;
    name: string;
    child_ids: { id: number; name: string }[];
}

interface ChildSubSidebarProps {
    className: string;
    closeAllCategory: any;
    closeChildSubCategory: any;
    isChildSubSidebarClose: boolean;
    activeCategoryName: any;
    categoryData: Category[];
}

export const ChildSubSidebar = (props: ChildSubSidebarProps) => {
    const [currentCategoryData, setCurrentCategoryData] = useState<Category[]>(props.categoryData || []);
    const [parentStack, setParentStack] = useState<Category[][]>([]);
    const [activeCategoryName, setActiveCategoryName] = useState(props.activeCategoryName || "");
    const [categoryHasChildren, setCategoryHasChildren] = useState<{ [key: number]: boolean }>({});
    const navigate = useNavigate();

    useEffect(() => {
        setCurrentCategoryData(props.categoryData || []);
        setActiveCategoryName(props.activeCategoryName || "");
        setCategoryHasChildren({});
        checkCategoriesForChildren(props.categoryData);
    }, [props.categoryData, props.activeCategoryName]);

    const checkIfCategoryHasChildren = async (categoryId: number) => {
        const result = await getChildCategories(categoryId);
        return result && !result.error && result.data && result.data.data.length > 0;
    };

    const checkCategoriesForChildren = async (categories: Category[]) => {
        const updatedCategoryHasChildren: { [key: number]: boolean } = {};
        for (const category of categories) {
            for (const child of category.child_ids) {
                const hasChildren = await checkIfCategoryHasChildren(child.id);
                updatedCategoryHasChildren[child.id] = hasChildren;
            }
        }
        setCategoryHasChildren(updatedCategoryHasChildren);
    };

    const handleCategoryClick = async (categoryId: number, categoryName: string) => {
        const hasChildren = categoryHasChildren[categoryId];
        if (hasChildren) {
            const result = await getChildCategories(categoryId);
            if (result && !result.error && result.data && result.data.data.length > 0) {
                setParentStack([...parentStack, currentCategoryData]);
                setCurrentCategoryData(result.data.data);
                setActiveCategoryName(categoryName);
            }
        } else {
            navigate(`/products/${categoryId}`);
            props.closeAllCategory();
        }
    };

    const handleBackClick = () => {
        const previousCategoryData = parentStack.pop();
        if (previousCategoryData) {
            setCurrentCategoryData(previousCategoryData);
            setActiveCategoryName(props.activeCategoryName);
            setParentStack([...parentStack]);
        } else {
            props.closeChildSubCategory();
        }
    };

    return (
        <div className={`childCategory ${!props.isChildSubSidebarClose ? props.className : ""}`}>
            <div className="childCategoryWrapper">
                <div className="childCategorySearch" onClick={handleBackClick}>
                    <ChevronLeftIcon />
                    <span>{activeCategoryName}</span>
                </div>
                <div className="childCategoryListWrapperScroll">
                    <div className="childCategoryListWrapper">
                        {currentCategoryData.map((category) => (
                            <ul key={category.id} className="childCategoryList">
                                <div className="childCategoryListName">
                                    {category?.name}
                                </div>
                                {category.child_ids.map((child: any) => (
                                    <li key={child.id} onClick={() => handleCategoryClick(child.id, child.name)}>
                                        <span>{child?.name}</span>

                                        {child.child_ids.length !== 0 && (
                                            <ChevronRightIcon className="childCategoryIcon" />
                                        )}
                                    </li>
                                ))}
                            </ul>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
