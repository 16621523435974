// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainSidebar {
  background-color: #F1F5F6;
  top: 0;
  max-width: 200px;
  width: 100%;
  height: initial;
  z-index: 1000;
}
.mainSidebar nav.ps-menu-root {
  height: initial !important;
}
.mainSidebar div.ps-sidebar-container {
  background-color: #F1F5F6;
  position: sticky !important;
  top: 0;
  gap: 40px;
  height: 100vh !important;
  overflow: initial !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ps-menu-root {
  height: 100%;
}
.ps-menu-root nav, .ps-menu-root ul {
  height: 100%;
}

.textInfoMenu__wrapper {
  padding: 20px;
}

.textInfoMenu {
  display: none;
  flex-direction: column;
  overflow: hidden;
  transition: 0.3s ease;
}
.textInfoMenu .textInfoSmall {
  font-size: 12px;
}
.textInfoMenu .textInfo {
  font-size: 14px;
}

li.ps-menuitem-root:first-child {
  padding-top: 10px;
}

.isActiveOverlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 997;
}

@keyframes sidebar-text-animation {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/sidebar/SideBar.sass"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,MAAA;EACA,gBAAA;EACA,WAAA;EACA,eAAA;EACA,aAAA;AACF;AACE;EACE,0BAAA;AACJ;AAEE;EACE,yBAAA;EACA,2BAAA;EACA,MAAA;EACA,SAAA;EACA,wBAAA;EACA,4BAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;AAAJ;;AASA;EACE,YAAA;AANF;AAOE;EACE,YAAA;AALJ;;AAOA;EACE,aAAA;AAJF;;AAMA;EACE,aAAA;EACA,sBAAA;EAEA,gBAAA;EACA,qBAAA;AAJF;AAOE;EACE,eAAA;AALJ;AAOE;EACE,eAAA;AALJ;;AAOA;EACE,iBAAA;AAJF;;AAMA;EACE,eAAA;EACA,OAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,8BAAA;EACA,YAAA;AAHF;;AAKA;EACE;IACE,UAAA;IACA,2BAAA;EAFF;EAIA;IACE,UAAA;IACA,wBAAA;EAFF;AACF","sourcesContent":[".mainSidebar\n  background-color: #F1F5F6\n  top: 0\n  max-width: 200px\n  width: 100%\n  height: initial\n  z-index: 1000\n\n  nav.ps-menu-root\n    height: initial!important\n\n\n  div.ps-sidebar-container\n    background-color: #F1F5F6\n    position: sticky!important\n    top: 0\n    gap: 40px\n    height: 100vh!important\n    overflow: initial!important\n    display: flex\n    flex-direction: column\n    justify-content: space-between\n\n\n\n.mainSidebar.ps-collapsed\n\n  .textInfoMenu\n\n\n.ps-menu-root\n  height: 100%\n  nav,ul\n    height: 100%\n\n.textInfoMenu__wrapper\n  padding: 20px\n\n.textInfoMenu\n  display: none\n  flex-direction: column\n\n  overflow: hidden\n  transition: 0.3s ease\n\n\n  .textInfoSmall\n    font-size: 12px\n\n  .textInfo\n    font-size: 14px\n\nli.ps-menuitem-root:first-child\n  padding-top: 10px\n\n.isActiveOverlay\n  position: fixed\n  left: 0\n  top: 0\n  width: 100%\n  height: 100%\n  background: rgba(0,0,0,.7)\n  z-index: 997\n\n@keyframes sidebar-text-animation\n  0%\n    opacity: 0\n    transform: translateY(100%)\n\n  100%\n    opacity: 1\n    transform: translateY(0)\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
